// routes
import { atom } from 'jotai';
import { PATH_DASHBOARD } from './routes/paths';
import { UserTypes } from '@api-types';

export const MAP_API = process.env.MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 68,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 62,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 36,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 20,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};

// Atoms

// User
export const userState = atom<UserTypes.UserViewModel | null>(null);

// User Settings
export const notificationSettingsState = atom({
  pushNotifications: false,
  emailNotifications: false,
});

export const pushNotificationsState = atom(false);

export const emailNotificationsState = atom(false);
