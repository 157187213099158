import { Box, List, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLocales } from '../../../locales';
import { StyledSubheader } from './styles';
import NavList from './NavList';
import NavListOnClick from './NavListOnClick';

interface NavSectionVerticalProps {
  sx?: any;
  data?: any;
}

const StyledList = styled(List)(({ theme }) => ({
  px: 0.5,
  '&::after': {
    content: '""',
    display: 'block',
    height: 1,
    boxShadow: `inset 0 -0.2px 0 0 ${theme.palette.common.white}`,
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
  },
}));

import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import SvgColor from '@/components/svg-color';
import { useAtom, useAtomValue } from 'jotai';
// import { navConfigAtom } from '@/hooks/useInitData';
import { useStateDataRequest } from '@/src/communication/socket/dataRequest';
import { SocketEmits } from '@api-types';
import navConfig from '@/layouts/dashboard/nav/config-navigation';
import { PATH_DASHBOARD } from '@/routes/paths';
import { atomWithStorage } from 'jotai/utils';
import AppConfig from '@/config/config';
import ChoosePortraitSetDropDown from '@/sections/dashboard/app/ChoosePortraitSetDropDown';

type NavigationData = { category: string }[];
const initData = atomWithStorage<NavigationData>('navigationData', []);

export default function NavSectionVertical({ sx, ...other }: NavSectionVerticalProps) {
  const { translate } = useLocales();
  const [showMore, setShowMore] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [globalData, setGlobalData] = useAtom(initData);
  const [navigationConfig, setNavigationConfig] = useState(navConfig);

  useStateDataRequest(SocketEmits.GetCategories, {}, setGlobalData);
  const icon = (name: string) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

  useEffect(() => {
    if (globalData && globalData.length > 0) {
      navConfig[1].items = globalData
        .map((category: { category: string; icon?: string }) => ({
          title: category.category,
          path: `${PATH_DASHBOARD.general.app}?discover=${encodeURIComponent(category.category)}`,
          icon: category.icon ? icon(category.icon) : icon('ic_blank'), // Use the icon from globalData if available
          img: category.category.startsWith('categories.')
            ? `${AppConfig.publicCategoryImages}/${category.category.replace('categories.', '')}.jpg`
            : undefined,
        }))
        .sort((a, b) => {
          const translatedTitleA = translate(a.title);
          const translatedTitleB = translate(b.title);
          return translatedTitleA.localeCompare(translatedTitleB);
        });

      setNavigationConfig(navConfig);
      setLoaded(true);
    }
  }, [globalData]);

  const handleClick = () => {
    setShowMore(!showMore);
  };

  // Nav auto expand
  const containerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    // If the ref is not attached to an element, do nothing.
    if (!containerRef.current) {
      return;
    }

    // Get the height of the container.
    const containerHeight = containerRef.current.offsetHeight;
    if (containerHeight === 0) {
      return;
    }

    if (containerHeight < window.innerHeight - 300) {
      // If the height of the container is less than the height of the viewport, show all items.
      setShowMore(true);
    } else {
      setShowMore(false);
    }
  }, [loaded]);

  return (
    <>
      <Stack sx={sx} {...other} ref={containerRef}>
        <ChoosePortraitSetDropDown />
        {loaded &&
          navigationConfig.map((group: { subheader?: string; subHeader?: string; items: any[] }, index: number) => {
            const showMaxEntries = 5;
            const key = group.subheader || group.items[0].title;
            const items = showMore ? group.items : group.items.slice(0, showMaxEntries);

            return (
              <StyledList key={key} disablePadding sx={{ mt: 2 }}>
                {group.subheader && <StyledSubheader disableSticky index={index}>{`${translate(group.subheader)}`}</StyledSubheader>}

                {items.map((list) => (
                  <NavList key={list.title + list.path} data={list} depth={1} hasChild={!!list.children} />
                ))}

                {group.items.length > showMaxEntries && !showMore && (
                  <NavListOnClick
                    key="showMore"
                    data={{
                      title: translate('menu.showMore'),
                      icon: icon('arrow-down-s-line'),
                      path: '###',
                    }}
                    depth={1}
                    hasChild={false}
                    onClicking={handleClick}
                  />
                )}
                {group.items.length > showMaxEntries && showMore && (
                  <NavListOnClick
                    key="showLess"
                    data={{
                      title: translate('menu.showLess'),
                      icon: icon('arrow-up-s-line'),
                      path: '###',
                    }}
                    depth={1}
                    hasChild={false}
                    onClicking={handleClick}
                  />
                )}
              </StyledList>
            );
          })}
      </Stack>
    </>
  );
}
